export const AboutUsSection = () => import('../../components/storyblok/AboutUsSection.vue' /* webpackChunkName: "components/about-us-section" */).then(c => wrapFunctional(c.default || c))
export const Accordion = () => import('../../components/storyblok/Accordion.vue' /* webpackChunkName: "components/accordion" */).then(c => wrapFunctional(c.default || c))
export const AccordionItem = () => import('../../components/storyblok/AccordionItem.vue' /* webpackChunkName: "components/accordion-item" */).then(c => wrapFunctional(c.default || c))
export const ActiveOffers = () => import('../../components/storyblok/ActiveOffers.vue' /* webpackChunkName: "components/active-offers" */).then(c => wrapFunctional(c.default || c))
export const AllCategoriesPreview = () => import('../../components/storyblok/AllCategoriesPreview.vue' /* webpackChunkName: "components/all-categories-preview" */).then(c => wrapFunctional(c.default || c))
export const BackButtonBlock = () => import('../../components/storyblok/BackButtonBlock.vue' /* webpackChunkName: "components/back-button-block" */).then(c => wrapFunctional(c.default || c))
export const BannerUSERCOM = () => import('../../components/storyblok/BannerUSERCOM.vue' /* webpackChunkName: "components/banner-u-s-e-r-c-o-m" */).then(c => wrapFunctional(c.default || c))
export const BlogArticle = () => import('../../components/storyblok/BlogArticle.vue' /* webpackChunkName: "components/blog-article" */).then(c => wrapFunctional(c.default || c))
export const BlogFile = () => import('../../components/storyblok/BlogFile.vue' /* webpackChunkName: "components/blog-file" */).then(c => wrapFunctional(c.default || c))
export const BlogHero = () => import('../../components/storyblok/BlogHero.vue' /* webpackChunkName: "components/blog-hero" */).then(c => wrapFunctional(c.default || c))
export const BlogHeroTile = () => import('../../components/storyblok/BlogHeroTile.vue' /* webpackChunkName: "components/blog-hero-tile" */).then(c => wrapFunctional(c.default || c))
export const BrandSection = () => import('../../components/storyblok/BrandSection.vue' /* webpackChunkName: "components/brand-section" */).then(c => wrapFunctional(c.default || c))
export const ButtonBlock = () => import('../../components/storyblok/ButtonBlock.vue' /* webpackChunkName: "components/button-block" */).then(c => wrapFunctional(c.default || c))
export const CategoriesTilesHoverSection = () => import('../../components/storyblok/CategoriesTilesHoverSection.vue' /* webpackChunkName: "components/categories-tiles-hover-section" */).then(c => wrapFunctional(c.default || c))
export const CategoriesTilesSection = () => import('../../components/storyblok/CategoriesTilesSection.vue' /* webpackChunkName: "components/categories-tiles-section" */).then(c => wrapFunctional(c.default || c))
export const CategoryPreview = () => import('../../components/storyblok/CategoryPreview.vue' /* webpackChunkName: "components/category-preview" */).then(c => wrapFunctional(c.default || c))
export const Column = () => import('../../components/storyblok/Column.vue' /* webpackChunkName: "components/column" */).then(c => wrapFunctional(c.default || c))
export const ContactForm = () => import('../../components/storyblok/ContactForm.vue' /* webpackChunkName: "components/contact-form" */).then(c => wrapFunctional(c.default || c))
export const ContactNavigation = () => import('../../components/storyblok/ContactNavigation.vue' /* webpackChunkName: "components/contact-navigation" */).then(c => wrapFunctional(c.default || c))
export const Container = () => import('../../components/storyblok/Container.vue' /* webpackChunkName: "components/container" */).then(c => wrapFunctional(c.default || c))
export const CookieDeclarationBlock = () => import('../../components/storyblok/CookieDeclarationBlock.vue' /* webpackChunkName: "components/cookie-declaration-block" */).then(c => wrapFunctional(c.default || c))
export const ExpandTextBlock = () => import('../../components/storyblok/ExpandTextBlock.vue' /* webpackChunkName: "components/expand-text-block" */).then(c => wrapFunctional(c.default || c))
export const GridBrandsSection = () => import('../../components/storyblok/GridBrandsSection.vue' /* webpackChunkName: "components/grid-brands-section" */).then(c => wrapFunctional(c.default || c))
export const HeaderSlide = () => import('../../components/storyblok/HeaderSlide.vue' /* webpackChunkName: "components/header-slide" */).then(c => wrapFunctional(c.default || c))
export const HeaderSlider = () => import('../../components/storyblok/HeaderSlider.vue' /* webpackChunkName: "components/header-slider" */).then(c => wrapFunctional(c.default || c))
export const IFrameBlok = () => import('../../components/storyblok/IFrameBlok.vue' /* webpackChunkName: "components/i-frame-blok" */).then(c => wrapFunctional(c.default || c))
export const InfoTile = () => import('../../components/storyblok/InfoTile.vue' /* webpackChunkName: "components/info-tile" */).then(c => wrapFunctional(c.default || c))
export const InfoTileBackgroundImage = () => import('../../components/storyblok/InfoTileBackgroundImage.vue' /* webpackChunkName: "components/info-tile-background-image" */).then(c => wrapFunctional(c.default || c))
export const LabelTitle = () => import('../../components/storyblok/LabelTitle.vue' /* webpackChunkName: "components/label-title" */).then(c => wrapFunctional(c.default || c))
export const LoadWhenVisible = () => import('../../components/storyblok/LoadWhenVisible.vue' /* webpackChunkName: "components/load-when-visible" */).then(c => wrapFunctional(c.default || c))
export const MapWithSingleStore = () => import('../../components/storyblok/MapWithSingleStore.vue' /* webpackChunkName: "components/map-with-single-store" */).then(c => wrapFunctional(c.default || c))
export const MapWithStores = () => import('../../components/storyblok/MapWithStores.vue' /* webpackChunkName: "components/map-with-stores" */).then(c => wrapFunctional(c.default || c))
export const MobileCarouselPcColumns = () => import('../../components/storyblok/MobileCarouselPcColumns.vue' /* webpackChunkName: "components/mobile-carousel-pc-columns" */).then(c => wrapFunctional(c.default || c))
export const News = () => import('../../components/storyblok/News.vue' /* webpackChunkName: "components/news" */).then(c => wrapFunctional(c.default || c))
export const Newsletter = () => import('../../components/storyblok/Newsletter.vue' /* webpackChunkName: "components/newsletter" */).then(c => wrapFunctional(c.default || c))
export const OfertaPracy = () => import('../../components/storyblok/OfertaPracy.vue' /* webpackChunkName: "components/oferta-pracy" */).then(c => wrapFunctional(c.default || c))
export const Page = () => import('../../components/storyblok/Page.vue' /* webpackChunkName: "components/page" */).then(c => wrapFunctional(c.default || c))
export const ProductLabels = () => import('../../components/storyblok/ProductLabels.vue' /* webpackChunkName: "components/product-labels" */).then(c => wrapFunctional(c.default || c))
export const ProductSlider = () => import('../../components/storyblok/ProductSlider.vue' /* webpackChunkName: "components/product-slider" */).then(c => wrapFunctional(c.default || c))
export const ProductSliderOld = () => import('../../components/storyblok/ProductSliderOld.vue' /* webpackChunkName: "components/product-slider-old" */).then(c => wrapFunctional(c.default || c))
export const ProductSliderWithTab = () => import('../../components/storyblok/ProductSliderWithTab.vue' /* webpackChunkName: "components/product-slider-with-tab" */).then(c => wrapFunctional(c.default || c))
export const Produkt = () => import('../../components/storyblok/Produkt.vue' /* webpackChunkName: "components/produkt" */).then(c => wrapFunctional(c.default || c))
export const PromoArticles = () => import('../../components/storyblok/PromoArticles.vue' /* webpackChunkName: "components/promo-articles" */).then(c => wrapFunctional(c.default || c))
export const ReadMoreSection = () => import('../../components/storyblok/ReadMoreSection.vue' /* webpackChunkName: "components/read-more-section" */).then(c => wrapFunctional(c.default || c))
export const RichText = () => import('../../components/storyblok/RichText.vue' /* webpackChunkName: "components/rich-text" */).then(c => wrapFunctional(c.default || c))
export const Row = () => import('../../components/storyblok/Row.vue' /* webpackChunkName: "components/row" */).then(c => wrapFunctional(c.default || c))
export const SingleIcon = () => import('../../components/storyblok/SingleIcon.vue' /* webpackChunkName: "components/single-icon" */).then(c => wrapFunctional(c.default || c))
export const SingleImage = () => import('../../components/storyblok/SingleImage.vue' /* webpackChunkName: "components/single-image" */).then(c => wrapFunctional(c.default || c))
export const SklepStacjonarny = () => import('../../components/storyblok/SklepStacjonarny.vue' /* webpackChunkName: "components/sklep-stacjonarny" */).then(c => wrapFunctional(c.default || c))
export const SliderBlok = () => import('../../components/storyblok/SliderBlok.vue' /* webpackChunkName: "components/slider-blok" */).then(c => wrapFunctional(c.default || c))
export const SliderMainBlok = () => import('../../components/storyblok/SliderMainBlok.vue' /* webpackChunkName: "components/slider-main-blok" */).then(c => wrapFunctional(c.default || c))
export const StronaStatyczna = () => import('../../components/storyblok/StronaStatyczna.vue' /* webpackChunkName: "components/strona-statyczna" */).then(c => wrapFunctional(c.default || c))
export const TableBlock = () => import('../../components/storyblok/TableBlock.vue' /* webpackChunkName: "components/table-block" */).then(c => wrapFunctional(c.default || c))
export const TrustmateMain = () => import('../../components/storyblok/TrustmateMain.vue' /* webpackChunkName: "components/trustmate-main" */).then(c => wrapFunctional(c.default || c))
export const WeatherMapBlok = () => import('../../components/storyblok/WeatherMapBlok.vue' /* webpackChunkName: "components/weather-map-blok" */).then(c => wrapFunctional(c.default || c))
export const YouTubeVideo = () => import('../../components/storyblok/YouTubeVideo.vue' /* webpackChunkName: "components/you-tube-video" */).then(c => wrapFunctional(c.default || c))
export const DynamicWrapper = () => import('../../components/storyblok/dynamicWrapper.vue' /* webpackChunkName: "components/dynamic-wrapper" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
